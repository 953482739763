<template>
  <div>
      <h1 style="font-size: 1.5rem; text-align: center; color: #777; padding-top: 2rem;">{{ $t('FAQ') }}</h1>
      <div v-html="dataset"></div>
  </div>
</template>

<script>
export default {
  data() {
      return {
          dataset: "",
          defaultThemeColor: "",
          isThemeDark: false,
      };
  },
  created() {
      eventBus.$on('theme-change', (data) => {
          if (data === 'theme-dark') {
              this.isThemeDark = true;
          } else {
              this.isThemeDark = false;
          }
      });
  },
  methods: {
      actInitFaq(flagActive) {

          if (flagActive) {
              window.actScrollView = function () {
                  const items = document.querySelectorAll("button");

                  for (let item of items) {
                      item.setAttribute("aria-expanded", false);
                      item.children[2] &&
                          item.children[2].setAttribute("aria-expanded", false);
                  }
                  document.getElementById("reset-password") &&
                      document
                          .getElementById("reset-password")
                          .setAttribute("aria-expanded", true);

                  window.scroll({
                      top:
                          document.getElementById("reset-password").getBoundingClientRect()
                              .top +
                          window.scrollY -
                          60,
                      left: 0,
                      behavior: "smooth",
                  });
              };
              setTimeout(() => {
                  const items = document.querySelectorAll("button");
                  function toggleAccordion(e) {
                      const itemToggle = this.getAttribute("aria-expanded");

                      for (let item of items) {
                          item.setAttribute("aria-expanded", false);
                          item.children[2] &&
                              item.children[2].setAttribute("aria-expanded", false);
                      }

                      if (itemToggle === "false") {
                          this.setAttribute("aria-expanded", true);
                          this.children[2] &&
                              this.children[2].setAttribute("aria-expanded", true);

                          var bodyRect = document.body.getBoundingClientRect(),
                              offset = e.target.top - bodyRect.top;

                          // window.scroll({
                          //         top: e.target.getBoundingClientRect().top + window.scrollY - 100,
                          //         left: 0,
                          //         behavior: 'smooth'
                          // });
                      }
                  }

                  items.forEach((item) =>
                      item.addEventListener("click", toggleAccordion)
                  );

                  let rightSideItemList = document.querySelectorAll(
                      ".category-title-faq"
                  );

                  let leftSideItemList = document.querySelectorAll(
                      ".accordion-parent-faq"
                  );

                  function actSelectedItem(e) {
                      e.stopPropagation();

                      console.error(e.target.dataset.identity);

                      rightSideItemList.forEach((rightSideItem) => {
                          rightSideItem.classList.remove("category-title-faq-active");
                      });

                      leftSideItemList.forEach((leftSideItem) => {
                          if (leftSideItem.dataset.identity == e.target.dataset.identity) {
                              leftSideItem.classList.remove("display-none");
                          } else {
                              leftSideItem.classList.add("display-none");
                          }
                      });

                      e.target.classList.add("category-title-faq-active");
                  }

                  rightSideItemList.forEach((rightSideItem) =>
                      rightSideItem.addEventListener("click", actSelectedItem)
                  );
              }, 500);

          } else {
              setTimeout(() => {
                  const items = document.querySelectorAll(".accordion-faq .outer-button");
                  const innerItems = document.querySelectorAll(".innerAccordion-faq .inner-button");
                  function toggleAccordion(e) {
                      console.log("toggleAccordion");
                      const itemToggle = this.getAttribute("aria-expanded");

                      for (let item of items) {
                          item.setAttribute("aria-expanded", false);
                          item.children[2] && item.children[2].setAttribute("aria-expanded", false);
                      }

                      if (itemToggle === "false") {

                          this.setAttribute("aria-expanded", true);
                          this.children[2] && this.children[2].setAttribute("aria-expanded", true);

                          var bodyRect = document.body.getBoundingClientRect(),
                              offset = e.target.top - bodyRect.top;

                          window.scroll({
                              top: e.target.getBoundingClientRect().top + window.scrollY - 10,
                              left: 0,
                              behavior: 'smooth'
                          });
                      }
                  }
                  function toggleInnerAccordion(e) {
                      console.log("toggleInnerAccordion");
                      console.log(e.target.offsetTop)
                      const itemToggle = this.getAttribute("aria-expanded");

                      for (let item of innerItems) {
                          item.setAttribute("aria-expanded", false);
                          item.children[2] && item.children[2].setAttribute("aria-expanded", false);
                      }

                      if (itemToggle === "false") {

                          this.setAttribute("aria-expanded", true);
                          this.children[2] && this.children[2].setAttribute("aria-expanded", true);

                          var bodyRect = document.body.getBoundingClientRect(),
                              offset = e.target.top - bodyRect.top;

                          window.scroll({
                              top: e.target.getBoundingClientRect().top + window.scrollY - 100,
                              left: 0,
                              behavior: 'smooth'
                          });
                      }
                  }

                  items.forEach((item) => item.addEventListener("click", toggleAccordion));
                  innerItems.forEach((item) => item.addEventListener("click", toggleInnerAccordion));

              }, 500)


              window.actScrollView = function () {

                  const items = document.querySelectorAll("button");

                  for (let item of items) {
                      item.setAttribute("aria-expanded", false);
                      item.children[2] && item.children[2].setAttribute("aria-expanded", false);
                  }
                  document.getElementById('reset-password') && document.getElementById('reset-password').setAttribute("aria-expanded", true);

                  window.scroll({
                      top: document.getElementById('reset-password').getBoundingClientRect().top + window.scrollY - 100,
                      left: 0,
                      behavior: 'smooth'
                  });
              }

          }

      },
      fetchFaqData(url) {
          fetch(url)
              .then((res) => {
                  return res.text();
              })
              .then((result) => {
                  this.dataset = result;
                  this.actInitFaq(window.screen.width > 720 ? true : false);
              });
      },
  },
  mounted() {

    document.title= "Frequently Asked Questions - Saina Play";     
  var allMetaElements = document.getElementsByTagName('meta');
  for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
  if (allMetaElements[metaValue].getAttribute("name") == "description") { 
       allMetaElements[metaValue].setAttribute('content',"Do you have any questions regarding our OTT or services? Learn more by reviewing Ultra Jhakaas frequently asked questions section."); 
  } 
 
  if(allMetaElements[metaValue].getAttribute("property") == "og:description") { 
 allMetaElements[metaValue].setAttribute('content', ""); 
  } 

  if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
     allMetaElements[metaValue].setAttribute('content', ""); 
  } 

  if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
     allMetaElements[metaValue].setAttribute('content', "frequently asked questions, FAQs, Saina Play FAQ,"); 
  }

  if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
     allMetaElements[metaValue].setAttribute('content', "FAQs: Frequently Asked Questions - Saina Play"); 
  }

  if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
     allMetaElements[metaValue].setAttribute('content', "FAQs: Frequently Asked Questions - Saina Play"); 
  }
}


      this.defaultThemeColor = localStorage.getItem('theme') || 'theme-dark';
      this.isThemeDark = this.defaultThemeColor === 'theme-dark';

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);

      let faqCodeDesktop ="https://static-templates.web.app/saniaplay/faq/faq-web.html";
      let faqDesktopLight ="https://static-templates.web.app/saniaplay/faq/faq-weblight.html";
      let faqCodeMobile = "https://static-templates.web.app/saniaplay/faq/faq.html";
      let faqMobileLight = "https://static-templates.web.app/saniaplay/faq/faqlight.html";

      let urlFaq;
      if (window.screen.width > 720) {
          urlFaq = this.isThemeDark ? faqCodeDesktop : faqDesktopLight;
      } else {
          urlFaq = this.isThemeDark ? faqCodeMobile : faqMobileLight;
      }

      this.fetchFaqData(urlFaq);
  },
};
</script>
